<template>
  <v-form>
    <v-row>
      <v-col
        cols="12"
      >
        <v-text-field
          v-model="form.deviceId"
          :label="$vuetify.lang.t('$vuetify.equipment.deviceId')"
          clearable
        />
      </v-col>
      <v-col
        cols="12"
      >
        <v-select
          v-model="form.category"
          :label="$vuetify.lang.t('$vuetify.equipmentTemplate.category')"
          :items="categoryItems"
          item-text="name"
          item-value="value"
          clearable
        ></v-select>
      </v-col>
      <v-col
        cols="12"
      >
        <v-btn
          class="mx-2 float-right"
          color="primary"
          fab
          dark
          small
          @click="handleSearch"
        >
          <v-icon dark>
            mdi-magnify
          </v-icon>
        </v-btn>
      </v-col>

    </v-row>
  </v-form>
</template>

<script>

  export default {
    data() {
      return {
        categoryItems: [
          {
            name: this.$vuetify.lang.t('$vuetify.equipmentCategory.leasing'),
            value: 'LEASING'
          },{
            name: this.$vuetify.lang.t('$vuetify.equipmentCategory.sale'),
            value: 'SALE'
          }
        ],
        form: {
          no: null,
          category: null
        }
      }
    },
    computed: {
    },
    created () {
      this.initialize();
    },
    methods: {
      initialize () {
      },
      handleSearch() {
        const data = {}
        for (let k in this.form) {
          if (this.form[k]) {
            data[k] = this.form[k]
          }
        }
        this.$emit('submit', data)
      }
    }
  }
</script>

<style lang="scss" scoped></style>
